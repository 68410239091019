import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { HeaderEnum } from 'gigabyte-shared';
import { log } from '../../utils/logger.util';

const axiosInstance: AxiosInstance = axios.create();

//TODO: useContext
axiosInstance.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const id = window.localStorage.getItem(HeaderEnum.USER_ID);

  config.baseURL = process.env.REACT_APP_API_BASE_URL;

  // TODO: review to remove "as unknow"
  config.headers = {
    ...config.headers,
    'x-api-key': process.env.REACT_APP_API_KEY,
    'Content-Type': 'application/json',
  } as unknown as AxiosRequestHeaders;

  if (accessToken !== null) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    } as unknown as AxiosRequestHeaders;

    if (config.headers['x-user-id'] === undefined) {
      config.headers = {
        ...config.headers,
        'x-user-id': id,
      } as unknown as AxiosRequestHeaders;
    }
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
